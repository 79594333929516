<template>
  <div>
    <h2 class="text-center p-t-10 p-b-10">目标完成情况</h2>

    <Row class="p-b-5">
      <i-col span="4">统计年度：</i-col>
      <i-col span="20">
        <RadioGroup v-model="chooseYear" size="small" type="button" button-style="solid">
          <Radio label="2020">2020</Radio>
          <Radio label="2021">2021</Radio>
          <Radio label="2022">2022</Radio>
          <Radio label="2023">2023</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row v-if="isQingDao&&kpiTitles.length>0" class="p-b-5">
      <i-col span="4">合约：</i-col>
      <i-col span="20" style="white-space: break-spaces;">
        <RadioGroup v-model="chooseKpiTitle" size="small" type="button" button-style="solid" @on-change="loadQingDaoData">
          <Radio v-for="item in kpiTitles" :key="item.id" :label="item.id">{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="table-title p-l-10 p-r-10" :gutter="8">
      <i-col span="3">周期</i-col>
      <i-col span="6" class="text-right">{{isQingDao? '发布费结算额':'发布费执行额'}}</i-col>
      <i-col span="6" class="text-right">{{isQingDao? '服务费结算额':'服务费执行额'}}</i-col>
      <i-col span="6" class="text-right">目标额</i-col>
      <i-col span="3" class="text-right">完成率</i-col>
    </Row>
    <Row v-for="(item,index) in tableData" :key="index" :gutter="8" class="p-t-5 p-l-10 p-r-10">
      <i-col span="3">{{item.name}}</i-col>
      <i-col span="6" class="text-right">{{formatNumber(item.useValue)}}</i-col>
      <i-col span="6" class="text-right">{{formatNumber(item.serviceValue)}}</i-col>
      <i-col span="6" class="text-right">{{formatNumber(item.targetValue)}}</i-col>
      <i-col span="3" class="text-right">{{item.rate}} {{item.rate==='--'?'':'%'}}</i-col>
    </Row>
    <p v-if="tableData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="remark p-t-5">* 表中金额单位:元</p>
    <p class="remark">* 结算额指在合同执行额的基础上，加上了调整项、罚款等,已完成经营方和代理商或者渠道商之间的财务结算金额</p>
  </div>
</template>

<script>
import { toMoneyNoPrefix } from '@/utils/wnumb_own'
import { listYearTargetRateGbQuarter, listKpiTitle, listKpiData } from '@/api/dw/kpi'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      chooseYear: '2022',
      isQingDao: false,

      kpiTitles: [], // 统计的类别目录
      chooseKpiTitle: null,

      tableData: []
    }
  },
  created () {
    this.isQingDao = this.publisherId === 13
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.tableData = []
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        year: this.chooseYear
      }

      if (this.isQingDao) {
        this.loadQingDaoTitles(queryModel)
      } else {
        listYearTargetRateGbQuarter(queryModel).then(res => {
          this.tableData = res
        })
      }
    },
    formatNumber (number) {
      return toMoneyNoPrefix(number)
    },
    loadQingDaoTitles (param) {
      listKpiTitle(param).then(res => {
        this.kpiTitles = res
        this.chooseKpiTitle = res.length > 0 ? res[0].id : null
        this.loadQingDaoData()
      })
    },
    loadQingDaoData () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        year: this.chooseYear,
        titleId: this.chooseKpiTitle
      }

      listKpiData(queryModel).then(res => {
        this.tableData = res
      })
    }
  },
  computed: {
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    companyId () {
      this.initPageData()
    },
    chooseYear () {
      this.initPageData()
    }
  }
}
</script>

<style>

</style>
